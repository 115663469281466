import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, navigate } from "gatsby";
// import Logo from '../design/logo'
import Headroom from "react-headroom";
import { useCookies } from "react-cookie";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { decodeCookie } from "../../utils/constants.js";

// Handle smooth scrolling
const smoothScroll = (elName) => {
  elName = elName.replace("#", "");
  let el = document.getElementById(elName);
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
    });
  }
};

// Process logout
const logOut = (token, setShowNav) => {
  // First, disable the body lock
  clearAllBodyScrollLocks();

  // Now invalidate the token
  fetch(
    "https://www.arcgis.com/sharing/rest/oauth2/revokeToken?f=json&client_id=VR5Q64LqZPUmbDyB&auth_token=" +
      token,
    { method: "post" }
  )
    .then((response) => response.json())
    .then((resData) => {
      navigate("/");
    });
};

const Nav = ({
  page,
  items,
  showAlertModal,
  setShowAlertModal,
  alertNumber,
}) => {
  let [showNav, setShowNav] = useState(false);
  let mobileNavRef = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies(["paverReg"]);

  // Safety check so we don't crash the app
  if (!cookies.paverReg) {
    cookies.paverReg = {};
  } else {
    // Decode the result
    cookies.paverReg = decodeCookie(cookies.paverReg);
  }

  useEffect(() => {
    // If we're expanding the mobile menu, lock the scroll
    if (mobileNavRef.current) {
      if (showNav) {
        disableBodyScroll(mobileNavRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
    }
  }, [showNav]);

  // Conditionally lazyload if we want to and have the capability
  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <div className={showNav ? "mobile-out" : ""}>
      <ConditionalWrapper
        condition={page}
        wrapper={(children) => <Headroom>{children}</Headroom>}
      >
        <div className="nav">
          <div className="left-nav">
            {page ? (
              <Fragment>
                {page === "landing" ? (
                  <Link to="/">
                    <img
                      className="hidetop"
                      src="/logos/red-logo.svg"
                      alt="PaverOps"
                    />
                  </Link>
                ) : (
                  <Link to="/">
                    <img src="/logos/red-logo.svg" alt="PaverOps" />
                  </Link>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div className="small-logo">
                  <Link to="/">
                    <img className="logo" src="/logos/square-logo.svg" />
                  </Link>
                </div>
                <label className="full-name">
                  {cookies.paverReg.fullname}
                  <br />
                  {cookies.paverReg.role}
                </label>
              </Fragment>
            )}
          </div>
          <div className={showNav ? "active right-nav" : "right-nav"}>
            {!page && (
              <div
                className="notification-icon-wrapper"
                onClick={() => setShowAlertModal(!showAlertModal)}
              >
                <img src="/icons/bell.svg" className="notification-icon" />
                {alertNumber !== null && alertNumber > 0 && (
                  <div className="notification-count">{alertNumber}</div>
                )}
              </div>
            )}
            <ul className="nav-list" ref={mobileNavRef}>
              {page !== "landing" && (
                <li>
                  <Link className="mobile-home-link" to={"/"}>
                    <button>Home</button>
                  </Link>
                </li>
              )}
              {items &&
                items.map((item, i) => {
                  return (
                    <li
                      className={
                        item.text.toLowerCase() === "login" ? "login" : ""
                      }
                      key={"li" + i}
                      onClick={() => {
                        clearAllBodyScrollLocks();
                        setShowNav(false);
                      }}
                    >
                      {item.url.indexOf("#") === 0 ? (
                        <span
                          onClick={() => {
                            setTimeout(() => {
                              smoothScroll(item.url);
                            }, 200);
                          }}
                        >
                          <button>{item.text}</button>
                        </span>
                      ) : (
                        <Link to={item.url}>
                          <button
                            className={
                              item.text.toLowerCase() === "login" ? "login" : ""
                            }
                            tabIndex="-1"
                          >
                            {item.text}
                          </button>
                        </Link>
                      )}
                    </li>
                  );
                })}
              {page !== "landing" && page !== "faq" && (
                <li>
                  <button
                    onClick={() => {
                      logOut(cookies.paverReg.token);
                    }}
                  >
                    Logout
                  </button>
                </li>
              )}
            </ul>
            <svg
              tabIndex="0"
              onClick={() => {
                setShowNav(!showNav);
              }}
              className="x-mobile-nav"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
            <svg
              onClick={() => {
                setShowNav(!showNav);
              }}
              className="hamburger"
              viewBox="0 0 105 80"
              width="40"
              height="40"
            >
              {/*<rect fill="white" width="100" height="15" y="5" x="5"></rect>*/}
              <rect width="100" height="15"></rect>
              {/*<rect fill="white" width="100" height="15" y="35" x="5"></rect>*/}
              <rect y="30" width="100" height="15"></rect>
              {/*<rect fill="white" width="100" height="15" y="65" x="5"></rect>*/}
              <rect y="60" width="100" height="15"></rect>
            </svg>
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export default Nav;
